import $ from "jquery";

        var overallHeight = $(window).height(),
            overallWidth = $(window).width(),
            scroll = 0,
            sm = 576,
            md = 768,
            lg = 992,
            xl = 1200,
            xxl = 1400;

        $(window).resize(function () {
            overallHeight = $(window).height();
            overallWidth = $(window).width();
        });

        $(window).scroll(function () {
            scroll = $('body').scrollTop();
        });

        // PLACE SEARCH
        function placeSearch() {
            if (overallWidth >= 768) {
                $('.topSearch').prependTo('.topSearchWrapper');
            } else {
                $('.topSearch').prependTo('.mainNav');
            }
        }
        placeSearch();


        $(window).resize(function () {
            placeSearch();
        });

        // SEARCH TOGGLE
        $('.topSearchToggle').click(function () {
            if ($(this).hasClass('on')) {
                $(this).removeClass('on');
                $('.topSearch').removeClass('on');
            } else {
                $(this).addClass('on');
                $('.topSearch').addClass('on');
            }
        });

        // MENU TOGGLE
        $('.menuToggle').click(function () {
            if ($('.mainNav').hasClass('on')) {
                $('.mainNav').removeClass('on');
                $('.navShade').removeClass('on');
            } else {
                $('.mainNav').addClass('on');
                $('.navShade').addClass('on');
            }
        });

        $('.navShade, .mainNavClose').click(function () {
            $('.mainNav').removeClass('on');
            $('.navShade').removeClass('on');
        });

        // MAIN NAV
        $('.mainNav .container > ul > li').each(function () {
            if ($('li', this).length) {
                $(this).addClass('hasChildren');
                $(this).append('<div class="navDropToggle"></div>');
            }
        });

        $('.mainNav .container ul li').hover(function () {
            if (overallWidth >= md) {
                $('> ul', this).stop().slideDown(450, 'easeOutQuint');
            }
        }, function () {
            if (overallWidth >= md) {
                $('>ul', this).stop().slideUp(450, 'easeOutQuint');
            }
        });

        $('.navDropToggle').click(function () {
            $('.mainNav ul li ul').slideUp();
            if (!$(this).hasClass('on')) {
                $(this).parent().find('> ul').slideDown();
                $(this).addClass('on');
            } else {
                $(this).removeClass('on');
            }
        });

        // ALERT CLOSE FUNCTION
        $('.alertPanelClose').click(function () {
            $('.alertPanel').slideUp();
        });

        // HIDE AND SHOW HEADER
        var headerHeight = $('header').outerHeight(true);
        var headerPos = $('header').offset().top;

        //$(window).scrollIntent();

        $(window).scroll(function () {
            headerScroll();
        });

        function headerScroll() {
            if ($('html').attr('scroll-intent') === 'down' && scroll >= headerHeight) {
                $('header').addClass('hide');
            } else {
                $('header').removeClass('hide');
            }
        }

        // FAQ TOGGLE
        $('.faqQuestion, .faqToggle').click(function () {
            if ($(this).hasClass('on')) {
                $(this).parent('.faqBox').find('.faqAnswer').removeClass('on').slideUp('fast', 'easeInOutQuad');
                $(this).parent('.faqfaqBoxQuestion').find('.faqQuestion').removeClass('on');
                $(this).parent('.faqBox').find('.faqToggle').removeClass('on').text('Show Answer');
            } else {
                $(this).parent('.faqBox').find('.faqAnswer').addClass('on').slideDown('fast', 'easeInOutQuad');
                $(this).parent('.faqBox').find('.faqQuestion').addClass('on');
                $(this).parent('.faqBox').find('.faqToggle').addClass('on').text('Hide Answer');;
            }
        });


        $('.agendaToggle').click(function () {
            if ($(this).hasClass('on')) {
                $(this).parent('.agendaBox').find('.agendaChild').removeClass('on').slideUp('fast', 'easeInOutQuad').attr('aria-hidden','true');
                $(this).parent('.agendaBox').find('.agendaParent').removeClass('on');
                $(this).parent('.agendaBox').find('.agendaToggle').removeClass('on').attr('aria-expanded','false');
            } else {
                $(this).parent('.agendaBox').find('.agendaChild').addClass('on').slideDown('fast', 'easeInOutQuad').attr('aria-hidden','false');
                $(this).parent('.agendaBox').find('.agendaParent').addClass('on');
                $(this).parent('.agendaBox').find('.agendaToggle').addClass('on').attr('aria-expanded','true');
            }
        });

        // AGENDA LISTING TABS
        $('#myTab a').on('click', function (event) {
            event.preventDefault()
            $(this).tab('show')
          })


        

        // STUPID FORM ANIMATION
        $('.inputDiv').each(function () {
            var placeholdText = $(this).find('input, textarea').attr('placeholder');

            if ($('input', this).val()) {
                $(this).closest('.inputDiv').addClass('on focus');
            }

            //$(this).find('input').attr('placeholder', '')
            //$(this).append('<span>' + placeholdText + '</span>');
        });

        $('.contactForm .inputDiv input, .contactForm .inputDiv textarea').focus(function () {
            $(this).closest('.inputDiv').addClass('on focus');
        });

        $('.contactForm .inputDiv input, .contactForm .inputDiv textarea').blur(function () {
            $(this).closest('.inputDiv').removeClass('focus');
            if (!$(this).val()) {
                $(this).closest('.inputDiv').removeClass('on');
            }
        });

        // SEARCH FUNCTIONALITY - TOP SEARCH
        $("div.topSearch input[type=text]").focus(function () {
            $(this).val('');
        });

        $("div.topSearch input[type=text]").focusout(function () {
            if ($(this).val() == '') { $(this).val('Enter Search Text Here'); }
        });

        $("div.topSearch input[type=text]").keypress(function (e) {
            if (e.which == 13) {
                $("div.topSearch input[type=submit]").click();
                return false;
            }
        });

        $("div.topSearch input[type=submit]").click(function (e) {
            e.preventDefault();
            var searchArg = $.trim($("div.topSearch input[type=text]").val());

            var url = "/search-results?searchtext=" + searchArg + "&searchmode=any";
            location.href = url;
            return false;
        });

        // SEARCH FUNCTIONALITY - NEWS PAGE
        $("div.filters input[type=text]").keypress(function (e) {
            if (e.which == 13) {
                e.preventDefault();
                $("#btnSubmit").click();
                return false;
            }

        });

        // SEARCH FUNCTIONALITY - AM ABSTRACT LISTING PAGE

        $(document).on("keypress", "div.abstract--filter-group input[type=text]", function (e) {
            if (e.which == 13) {
                e.preventDefault();
                $("#btn_Search").click();
                return false;
            }
        });


        // TYPE AHEAD SEARCH
        $('.topSearch input[type=text]').autocomplete({
            source: '/BlueKey/ASGCT_Templates/SuggestiveSearch.aspx',
            minLength: 2,
            delay: 250,
            appendTo: ".topSearch",
            select: function (event, ui) {
                location.href = ui.item.value;
                return false;
            }
        });

        // INTERIOR SLIDESHOW
        var interiorSlideCurrent = 0;
        var interiorSlideTotal;
        function interiorSlideshowSetup() {
            $(".interiorSlide").each(function () {
                $(".interiorSlide-nav.dots").append("<div class='dot'></div>");
            });

            interiorSlideTotal = $(".interiorSlide").length;

            $(".interiorSlide").first().addClass("on");
            $(".interiorSlide-nav.dots .dot").first().addClass("active");
        }

        if ($(".interiorSlideshowWrapper").length) {
            interiorSlideshowSetup();
        }

        function interiorSlideNext() {
            $(".interiorSlide-nav.dots .dot").removeClass("active");
            $(".interiorSlide-nav.dots .dot").eq(interiorSlideCurrent).addClass("active");

            $(".interiorSlide").removeClass("on");
            $(".interiorSlide").eq(interiorSlideCurrent).addClass("on");
        }

        $(".interiorSlide-action.prev").click(function () {
            if (interiorSlideCurrent <= 0) {
                interiorSlideCurrent = interiorSlideTotal - 1;
            } else {
                interiorSlideCurrent = interiorSlideCurrent - 1;
            }

            interiorSlideNext();
        });

        $(".interiorSlide-action.next").click(function () {
            if (interiorSlideCurrent >= interiorSlideTotal - 1) {
                interiorSlideCurrent = 0;
            } else {
                interiorSlideCurrent++;
            }

            interiorSlideNext();
        });

        // VALIDATE MULTIPLE SELECTS
        $('.multipleSelect input:checkbox').on('change', function (evt) {
            if ($(this).siblings(':checked').length >= 3) {
                $(this).closest('.multipleSelect').find('.multipleSelect-validate').addClass('on');
                this.checked = false;
            } else {
                $(this).closest('.multipleSelect').find('.multipleSelect-validate').removeClass('on');
            }
        });

        // CATEGORY LIST IN SIDEBAR LIMITATION
        if ($(".categoryLimit").length) {
            var categoryCount = $(".categoryLimit ul li").length;
            if (categoryCount >= 5) {
                $(".categoryLimit li").slice(5).hide();
                $("<button class='show-more'>Show more</button>").insertAfter(".categoryLimit ul");
            }
        }

        $('.categoryLimit').on('click', '.show-more', function (event) {
            event.preventDefault ? event.preventDefault() : (event.returnValue = false);
            $(".categoryLimit li").show();
            $(this).hide();
        });


        // CLOSE HEADER ALERT
        $(".header-alert__close").click(function (e) {
            e.preventDefault();
            $(this).closest(".header-alert").slideUp(300);
        });

        // SHIM FOR IFRAMES
        if ($(".iMISiFrame").length > 0) {
            fixIframeMargin();
        }

        function fixIframeMargin() {
            var headerHeight = $("header").outerHeight();
            $(".iMISiFrame").css({ marginTop: headerHeight + "px" });
        }

        $(window).resize(function () {
            if ($(".iMISiFrame").length > 0) {
                fixIframeMargin();
            }
        });
        

 

    $(window).on('load', function () {


    });


    