import '../scss/app.scss';

import $ from "jquery";
require('jquery-ui-bundle');

require('../../node_modules/bootstrap/js/src/tab.js');
require('../../node_modules/bootstrap/js/src/collapse.js');

require('./general.js')
require('./header.js')

$(document).ready(function() {});

